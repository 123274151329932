var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("section", { staticClass: "home-distance" }, [
      _c(
        "div",
        { staticClass: "home-distance-select" },
        [_c("options-distance")],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "home-distance-submit" }, [
        _c(
          "button",
          {
            staticClass: "button button-submit",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                _vm.$router.push({ name: _vm.$pathname.result })
              }
            }
          },
          [_vm._v("検索")]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "aside",
      { staticClass: "home-ad" },
      [
        _c("area-ad", {
          attrs: {
            adclient: "ca-pub-0251375114144720",
            adslot: "1573121416",
            adresponsive: "true",
            adformat: "auto"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("section", { staticClass: "home-caution" }, [
      _c(
        "ul",
        { staticClass: "caution" },
        _vm._l(_vm.caution, function(list) {
          return _c("li", {
            key: list,
            staticClass: "caution-list",
            domProps: { innerHTML: _vm._s(list) }
          })
        })
      )
    ]),
    _vm._v(" "),
    _c("footer", { staticClass: "copy" }, [
      _c("small", { staticClass: "copy-author" }, [
        _vm._v("© " + _vm._s(_vm.author))
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "home-logo" }, [
      _c("img", {
        attrs: {
          src: require("../../img/logo.svg"),
          width: "162",
          height: "28",
          alt: "KOKOIKU"
        }
      })
    ])
  }
]
render._withStripped = true
/* hot reload */
if (module.hot) {
  var api = require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (module.hot.data) {
      require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js").rerender('3c528514', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    }
  }
}export { render, staticRenderFns }