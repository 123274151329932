<template lang="pug">
  #ad.ad
    template(v-if="production")
      ins.adsbygoogle(:style="adStyle", :data-ad-client="adclient", :data-ad-slot="adslot", :data-ad-format="adformat", :data-full-width-responsive="adresponsive")
    template(v-else)
      .ad-span 広告
</template>

<style lang="stylus" scoped>
  .ad
    width: 100%
    &-span
      width: 100%
      height: 100px
      margin: 0 auto
      background: #cccccc
      border: 1px solid #999999
</style>

<script>
  export default {
    props: {
      adclient: {
        type: String,
        required: true
      },
      adslot: {
        type: String,
        required: true
      },
      adformat: {
        type: String,
        required: false
      },
      adstyle: {
        type: String,
        required: false,
        default: 'display: block'
      },
      adresponsive: {
        type: String,
        required: false
      }
    },
    computed: {
      production: () => IS_PRODUCTION
    },
    mounted () {
      /* eslint no-global-assign: "off" */
      if (this.production) (adsbygoogle = window.adsbygoogle || []).push({})
    }
  }
</script>