<template lang="pug">
  article.shop
    .shop-sec: h1.shop-name {{name}}
    .shop-sec: address.shop-address {{address}}
    .shop-info.shop-sec
      .shop-info-box
        shop-rating
        p.shop-info-distance 現在地から約{{distance}}m

      .shop-info-box
        ul.shop-data
          li.sho-data-list: a.external.yelp(:href="url", target="_blank"): img(src="../../../img/icon_yelp.svg", width=20, height=27, alt="")
          li.sho-data-list(v-if="phone"): a.external.phone(:href="phone", target="_blank"): img(src="../../../img/icon_phone.svg", width=20, height=20, alt="")
</template>

<style lang="stylus" scoped>
  @import "~stylus/_assets/modules/_getConsts.styl"

  $size = 40px
  $margin = round($base.space / 2)

  .shop
    background: rgba(#fff, 0.9)
    border-radius: 10px
    padding: $base.space

    &-sec
      margin-bottom: $base.space
      &:last-child
        margin-bottom: 0

    &-name
      font-size: $base.fz.title
      font-weight: bold

    &-info
      display: grid
      grid-template-columns: 1fr (($size * 2) + $margin)
      grid-column-gap: $margin

      &-distance
        margin: 0
        padding-top: round($base.space / 2)

    &-data
      list-style: none
      margin: 0
      padding: 0
      display: flex
      flex-flow: row wrap
      justify-content: space-between
      align-items: flex-start
      &-list
        vertical-align: top

  // 外部ボタン(yelp/Phone)
  .external
    text-decoration: none
    color: #fff
    display: flex
    flex-flow: column wrap
    justify-content: center
    align-items: center
    width: $size
    height: @width
    border-radius: 50%
    box-shadow: 1px 1px 4px #9B9B9B
    &.yelp
      background: #d32323
    &.phone
      background: #65D183
</style>

<script>
  import { mapState } from 'vuex'
  import shopRating from './shop-rating'

  export default {
    components: { shopRating },
    computed: {
      ...mapState({
        name: (state) => state.shop.name,
        address: (state) => state.shop.address,
        url: (state) => state.shop.url,
        phone: (state) => state.shop.phone,
        distance: (state) => state.shop.distance
      })
    }
  }
</script>