import Vuex from 'vuex'
import distance from './distance'
import shop from './shop'
import loading from './loading'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    distance,
    shop,
    loading
  }
})