<template lang="pug">
  transition(name="fade", mode="out-in")
    #splash.splash(v-if="show")
      #splash-container.splash-container
        #splash-arrow.splash-arrow: include ../parts/icon_loading_arrow.pug
        .splash-main: include ../parts/icon_splash.pug
</template>

<style lang="stylus" scoped>
  @import "~stylus/_assets/modules/_getConsts.styl"

  .splash
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    overflow: hidden
    background: $c.main
    z-index: $z.loading
    box-sizing: border-box
    display: flex
    flex-flow: column nowrap
    align-items: center
    justify-content: center

    &-container
      position: relative

    &-arrow
      position: absolute
      top: 6px
      left: 129px
      transform: rotate(-48deg)
      opacity: 0
      z-index: $z.loading

  #icon-splash
    width: 260px
    height: auto

  #icon-splash-circle
    transform-origin: center bottom

  #icon-loading-arrow
    width: 40px
    height: auto

</style>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import animejs from 'animejs'

  export default {
    data () {
      return {
        show: true
      }
    },
    computed: {
      ...mapGetters({
        notSplash: 'loading/notSplash'
      })
    },
    beforeMount () {
      if (!this.notSplash) this.finish()
    },
    mounted () {
      if (this.notSplash) {
        this.start()
      }
    },
    methods: {
      start () {
        this.$nextTick(() => {
          this.bg()
        })
      },

      bg () {
        animejs({
          targets: '#splash-container',
          opacity: 0,
          duration: 0
        })

        animejs({
          targets: '#splash',
          background: '#fff',
          duration: 600,
          delay: 1000,
          easing: 'linear',
          complete: () => {
            animejs({
              targets: '#splash-container',
              opacity: 1,
              duration: 0
            })
            this.logo()
          }
        })
      },

      logo () {
        const anime = animejs.timeline()

        anime
          .add({
            targets: '#icon-splash-circle',
            rotateX: [
              { value: -90, duration: 0 },
              { value: 0, delay: 600, duration: 500, easing: 'easeOutBack', elasticity: 1000 }
            ]
          })
          .add({
            targets: '#icon-splash .icon-splash-path',
            fill: '#fff',
            duration: 0
          })
          .add({
            targets: '#icon-splash .icon-splash-path',
            strokeDashoffset: [animejs.setDashoffset, 0],
            easing: 'easeInOutSine',
            duration: 1500,
            delay: function (el, i) { return i * 250 }
          })
          .add({
            targets: '#icon-splash .icon-splash-path',
            fill: '#000',
            duration: 800,
            easing: 'linear'
          })

        anime.finished.then(() => {
          this.arrow()
        })
      },

      arrow () {
        const anime = animejs.timeline()

        anime
          .add({
            targets: '#splash-arrow',
            offset: 0,
            opacity: 1,
            duration: 100,
            easing: 'linear'
          })
          .add({
            targets: '#icon-loading-arrow',
            offset: 0,
            translateX: [
              { value: 30, duration: 0 },
              { value: 0, duration: 100, easing: 'linear' }
            ]
          })
          .add({
            targets: '#icon-loading-arrow',
            direction: 'alternate',
            easing: 'easeInBack',
            elasticity: 1000,
            offset: 110,
            scaleX: [
              { value: 0.5, duration: 100 },
              { value: 1, duration: 100 }
            ]
          })

        anime.finished.then(() => {
          setTimeout(() => {
            this.endSplash()
            this.finish()
          }, 1000)
        })
      },

      finish () {
        this.show = false
      },

      ...mapActions({
        endSplash: 'loading/endSplash'
      })
    }
  }
</script>