<template lang="pug">
  .rating
    .rating-sec
      span.rating-data.star(:class="clsStar")
      span.rating-number {{rating}}
    .rating-sec(v-if="clsPrice")
      span.rating-data.price(:class="clsPrice")
</template>

<style lang="stylus" scoped>
  @import "~stylus/_assets/modules/_getConsts.styl"

  // 評価/価格アイコンサイズ
  $icon = 16px

  icon-size($count, $isHalf = false)
    $add = $isHalf ? $icon / 2 : 0
    return ($icon * $count) + $add

  .rating
    &-sec
      margin-bottom: round($base.space / 2)
      &:last-child
        margin-bottom: 0

    &-data
      position: relative
      display: inline-block
      vertical-align: middle
      height: $icon
      &::before
      &::after
        font-family: $fg-default
        font-size: $icon
        line-height: @font-size
        position: absolute
        font-weight: bold
        top: 0
        left: 0
        display: inline-block
        vertical-align: middle
        height: @height
      &::before
        color: #cccccc
      &::after
        overflow: hidden
        white-space: nowrap

      &.star
        width: icon-size(5)
        &::before
        &::after
          content: '★★★★★'
        &::after
          color: $c.main

      &.price
        width: icon-size(4)
        &::before
        &::after
          content: '￥￥￥￥'
        &::after
          color: $c.green

      &.rating-0::after
        width: icon-size(0)
      &.rating-0_5::after
        width: icon-size(0, true)
      &.rating-1::after
        width: icon-size(1)
      &.rating-1_5::after
        width: icon-size(1, true)
      &.rating-2::after
        width: icon-size(2)
      &.rating-2_5::after
        width: icon-size(2, true)
      &.rating-3::after
        width: icon-size(3)
      &.rating-3_5::after
        width: icon-size(3, true)
      &.rating-4::after
        width: icon-size(4)
      &.rating-4_5::after
        width: icon-size(4, true)
      &.rating-5::after
        width: icon-size(5)

    &-number
      display: inline-block
      margin-left: 0.5em
      vertical-align: middle
      &::before
        content: '('
      &::after
        content: ')'
</style>

<script>
  import { mapState, mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapState({
        rating: (state) => state.shop.rating
      }),
      ...mapGetters({
        clsStar: 'shop/clsStar',
        clsPrice: 'shop/clsPrice'
      })
    }
  }
</script>