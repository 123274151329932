const state = {
  current: null
}

const mutations = {
  update (state, { current }) {
    current = parseInt(current, 10)

    // 同一なら処理しない
    if (state.current === current) return false

    Vue.prototype.$storage.save('distance', current)
    state.current = current
  }
}

const getters = {}

const actions = {
  update: ({ commit }, { current }) => commit('update', { current })
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}