<template lang="pug">
  transition(name="fade", mode="out-in")
    .modal(v-if="show", @click.stop.prevent="close")
      .modal-container(@click.stop.prevent)
        .modal-contents
          options-distance.modal-sec

          .modal-sec.modal-center: button.button.button-submit(type="button", @click.stop.prevent="search") 再検索

          .modal-sec(style="text-align:center"): area-ad(adclient="ca-pub-0251375114144720", adslot="2480198340", adstyle="display:inline-block;width:234px;height:60px")

          .modal-sec
            ul.caution
              li.caution-list(v-for="list in caution", :key="list", v-html="list")

        button.modal-close.button.button-image(type="button", @click.stop.prevent="close"): img(src="../../../img/button_close.svg", width=40, height=40, alt="close")
</template>

<style lang="stylus" scoped>
  @import "~stylus/_assets/modules/_getConsts.styl"

  .modal
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    overflow: hidden
    background: rgba(#000, 0.8)
    color: #ffffff
    display: flex
    flex-flow: row nowrap
    align-items: center
    justify-content: center
    z-index: $z.modal

    &-container
      width: s('calc(100% - %s)', $base.space * 2)
      height: 80%
      background: #ffffff
      border-radius: 5px
      position: relative
      box-sizing: border-box
      z-index: $z.modal
      touch-action: none

    &-contents
      overflow-y: auto
      -webkit-overflow-scrolling: touch
      height: 100%
      padding: ($base.space * 2) $base.space

    &-close
      position: absolute
      top: -15px
      right: -15px
      z-index: ($z.modal + 1)

    &-sec
      margin-bottom: $base.space
      &:last-child
        margin-bottom: 0

    &-center
      text-align: center
</style>

<script>
  import { mapActions, mapState } from 'vuex'
  import { caution } from '../../consts/caution'
  import AreaAd from './ad'
  import OptionsDistance from './options-distance'

  export default {
    components: { OptionsDistance, AreaAd },
    data () {
      return {
        show: false,
        tempDistance: null
      }
    },
    computed: {
      caution () {
        return [caution.yelp]
      },
      ...mapState({
        distance: (state) => state.distance.current
      })
    },
    created () {
      this.$bus.$on('setting-open', () => {
        this.show = true
      })
      this.tempDistance = this.distance
    },
    mounted () {
      // this.tempDistance = this.distance
    },
    beforeDestroy () {
      this.$bus.$off('setting-open')
    },
    methods: {
      search () {
        this.$bus.$emit('result-search')
        this.show = false
      },

      close () {
        this.updateDistance({ current: this.tempDistance })
        this.show = false
      },

      ...mapActions({
        updateDistance: 'distance/update'
      })
    }
  }
</script>