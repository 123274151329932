const state = {
  name: null,
  url: null,
  phone: null,
  address: null,
  distance: 0,
  rating: 0,
  price: null
}

const mutations = {
  update (state, { data }) {
    const { name, url, distance, rating, price } = data
    const phone = data['display_phone']

    // 同じ店舗なら処理しない
    if (name === state.name) return false

    state.name = name
    state.url = url
    state.phone = phone ? `tel:${phone}` : null
    state.address = data['location']['display_address'].join('')
    state.distance = Math.floor(distance)
    state.rating = rating
    state.price = price == null ? null : price.length
  },
  reset (state) {
    state.name = null
    state.url = null
    state.phone = null
    state.distance = 0
    state.rating = 0
    state.price = null
  }
}

const getters = {
  clsStar: (state) => `rating-${state.rating.toString().replace(/\./, '_')}`,
  clsPrice: (state) => state.price === null ? '' : `rating-${state.price}`
}

const actions = {
  update: ({ commit }, { data }) => commit('update', { data }),
  reset: ({ commit }) => commit('reset')
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}