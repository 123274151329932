var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rating" }, [
    _c("div", { staticClass: "rating-sec" }, [
      _c("span", { staticClass: "rating-data star", class: _vm.clsStar }),
      _c("span", { staticClass: "rating-number" }, [_vm._v(_vm._s(_vm.rating))])
    ]),
    _vm._v(" "),
    _vm.clsPrice
      ? _c("div", { staticClass: "rating-sec" }, [
          _c("span", { staticClass: "rating-data price", class: _vm.clsPrice })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
/* hot reload */
if (module.hot) {
  var api = require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (module.hot.data) {
      require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js").rerender('006509b6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    }
  }
}export { render, staticRenderFns }