<template lang="pug">
  .gps
    #gps-container.gps-container
      .gps-box
        #gps-text.gps-text
          span.gps-text-word 位
          span.gps-text-word 置
          span.gps-text-word 情
          span.gps-text-word 報
          span.gps-text-word 取
          span.gps-text-word 得
          span.gps-text-word 中

        .gps-loading
          include ../../parts/icon_gps.pug
</template>

<style lang="stylus" scoped>
  @import "~stylus/_assets/modules/_getConsts.styl"

  .gps
    width: 100%
    height: @width
    color: #ffffff
    position: relative
    overflow: hidden

    &-container
      box-sizing: border-box
      width: 300px
      height: @width
      z-index: $z.loading
      position: absolute
      overflow: hidden
      top: 0
      left: 0
      right: 0
      bottom: 0
      margin: auto
      border-radius: 50%
      border: 0 solid lighten($c.main, 30%)

    &-box
      display: flex
      flex-flow: column nowrap
      align-items: center
      justify-content: center
      width: 100%
      height: @width
      position: relative
      top: 0
      left: 0
      right: 0
      bottom: 0
      margin: auto

    &-text
      letter-spacing: 10px
      font-weight: bold
      font-size: $base.fz.title
      margin-bottom: $base.space
      white-space: nowrap

      &-word
        display: inline-block
        margin: 0 5px
        opacity: 0

    &-loading
      text-align: center

  .icon-gps-path
    opacity: 0

  #icon-gps-radar
    transform-origin: top center
    transform: rotate(-20deg)
</style>

<script>
  import animejs from 'animejs'

  export default {
    data () {
      return {}
    },
    computed: {},
    created () {
      this.$bus.$on('gps-finish', (cb) => {
        this.end(cb)
      })
    },
    beforeDestroy () {
      this.$bus.$off('gps-finish')
    },
    mounted () {
      this.$nextTick(() => {
        this.start()
      })
    },
    methods: {
      start () {
        animejs({
          targets: '#gps-text .gps-text-word',
          loop: true,
          direction: 'alternate',
          ease: 'easeInSine',
          elasticity: 0,
          scale: [1.3, 1],
          opacity: [0, 1],
          duration: (el, i, l) => (i * 200) + 200,
          delay: (el, i, l) => i * 200
        })

        animejs({
          targets: '.icon-gps-path',
          loop: true,
          ease: 'easeInQuart',
          elasticity: 0,
          opacity: 1,
          delay: (el, i, l) => i * 600
        })

        animejs({
          targets: '#icon-gps-radar',
          loop: true,
          elasticity: 200,
          rotate: [
            { value: -20, duration: 2000, ease: 'easeOutBack' },
            { value: 20, duration: 2000, ease: 'easeInBack' }
          ]
        })
      },

      end (cb) {
        animejs({
          targets: '#gps-container',
          duration: 2000,
          borderRadius: '50%',
          width: 0,
          height: 0,
          elasticity: 0,
          borderWidth: '6px',
          ease: 'linear',
          complete: () => {
            if (cb) cb()
          }
        })
      }
    }
  }
</script>