import { render, staticRenderFns } from "./area-yelp.vue?vue&type=template&id=4a48b564&scoped=true&lang=pug"
import script from "./area-yelp.vue?vue&type=script&lang=js"
export * from "./area-yelp.vue?vue&type=script&lang=js"
import style0 from "./area-yelp.vue?vue&type=style&index=0&id=4a48b564&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a48b564",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('4a48b564', component.options)
    } else {
      api.reload('4a48b564', component.options)
    }
  }
}
component.options.__file = "vue/modules/loading/area-yelp.vue"
export default component.exports