var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { staticClass: "shop" }, [
    _c("div", { staticClass: "shop-sec" }, [
      _c("h1", { staticClass: "shop-name" }, [_vm._v(_vm._s(_vm.name))])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "shop-sec" }, [
      _c("address", { staticClass: "shop-address" }, [
        _vm._v(_vm._s(_vm.address))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "shop-info shop-sec" }, [
      _c(
        "div",
        { staticClass: "shop-info-box" },
        [
          _c("shop-rating"),
          _vm._v(" "),
          _c("p", { staticClass: "shop-info-distance" }, [
            _vm._v("現在地から約" + _vm._s(_vm.distance) + "m")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "shop-info-box" }, [
        _c("ul", { staticClass: "shop-data" }, [
          _c("li", { staticClass: "sho-data-list" }, [
            _c(
              "a",
              {
                staticClass: "external yelp",
                attrs: { href: _vm.url, target: "_blank" }
              },
              [
                _c("img", {
                  attrs: {
                    src: require("../../../img/icon_yelp.svg"),
                    width: "20",
                    height: "27",
                    alt: ""
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _vm.phone
            ? _c("li", { staticClass: "sho-data-list" }, [
                _c(
                  "a",
                  {
                    staticClass: "external phone",
                    attrs: { href: _vm.phone, target: "_blank" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../../img/icon_phone.svg"),
                        width: "20",
                        height: "20",
                        alt: ""
                      }
                    })
                  ]
                )
              ])
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
/* hot reload */
if (module.hot) {
  var api = require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (module.hot.data) {
      require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js").rerender('0913d9b6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    }
  }
}export { render, staticRenderFns }