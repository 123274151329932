var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _vm.show
      ? _c("div", { staticClass: "fail" }, [
          _c("h1", { staticClass: "fail-title" }, [
            _vm._v(_vm._s(_vm.failText))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "fail-contents" }, [
            _c("div", { staticClass: "fail-sec" }, [_c("options-distance")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "fail-sec fail-center" }, [
              _c(
                "button",
                {
                  staticClass: "button button-submit",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      _vm.$bus.$emit("result-search")
                    }
                  }
                },
                [_vm._v("検索")]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "aside",
            { staticClass: "fail-sec" },
            [
              _c("area-ad", {
                attrs: {
                  adclient: "ca-pub-0251375114144720",
                  adslot: "1573121416",
                  adresponsive: "true",
                  adformat: "auto"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "fail-sec caution" },
            _vm._l(_vm.caution, function(list) {
              return _c("li", {
                key: list,
                staticClass: "caution-list",
                domProps: { innerHTML: _vm._s(list) }
              })
            })
          ),
          _vm._v(" "),
          _c("footer", { staticClass: "copy" }, [
            _c("small", { staticClass: "copy-author" }, [
              _vm._v("© " + _vm._s(_vm.author))
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
/* hot reload */
if (module.hot) {
  var api = require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (module.hot.data) {
      require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js").rerender('43cfaa2a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    }
  }
}export { render, staticRenderFns }