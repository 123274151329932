var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _vm.show
      ? _c("div", { staticClass: "success" }, [
          _c("div", { staticClass: "success-header" }, [
            _c("h1", { staticClass: "success-title" }, [
              _c("img", {
                attrs: {
                  src: require("../../../../img/logo.svg"),
                  width: "122",
                  height: "21",
                  alt: "KOKOIKU"
                }
              })
            ]),
            _c("small", { staticClass: "success-author" }, [
              _vm._v("© " + _vm._s(_vm.author))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "map", attrs: { id: "map" } }),
          _vm._v(" "),
          _c("div", { staticClass: "success-shop" }, [_c("shop-info")], 1),
          _vm._v(" "),
          _c("ul", { staticClass: "success-control" }, [
            _c("li", [
              _c(
                "button",
                {
                  staticClass: "button button-image",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      _vm.$bus.$emit("setting-open")
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../../img/button_settings.svg"),
                      alt: "",
                      width: "58",
                      height: "58"
                    }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "button",
                {
                  staticClass: "button button-image",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      _vm.$bus.$emit("result-search")
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../../img/button_research.svg"),
                      alt: "",
                      width: "58",
                      height: "58"
                    }
                  })
                ]
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
/* hot reload */
if (module.hot) {
  var api = require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (module.hot.data) {
      require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js").rerender('3b63f0c6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    }
  }
}export { render, staticRenderFns }