var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gps" }, [
    _c(
      "div",
      { staticClass: "gps-container", attrs: { id: "gps-container" } },
      [
        _c("div", { staticClass: "gps-box" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "gps-loading" }, [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "126",
                  height: "47",
                  viewBox: "0 0 126 47"
                }
              },
              [
                _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
                  _c("g", { attrs: { id: "icon-gps-radar", fill: "none" } }, [
                    _c("path", {
                      staticClass: "icon-gps-path",
                      attrs: {
                        fill: "#FFF",
                        "fill-rule": "nonzero",
                        d:
                          "M75.7725141,18.1545757 C72.5010079,21.4059971 67.9096783,23.4283027 62.8257478,23.4283027 C57.804197,23.4283027 53.2632329,21.4553199 50,18.2737037 L54.1525308,14.1211729 C56.3361524,16.3133536 59.4146275,17.6789832 62.8257478,17.6789832 C66.2992891,17.6789832 69.4278994,16.2629163 71.6179383,14 L75.7725141,18.1545757 Z"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "icon-gps-path",
                      attrs: {
                        fill: "#FFF",
                        "fill-rule": "nonzero",
                        d:
                          "M62.7838354,34.8402608 C54.7366359,34.8402608 47.4226961,31.6612046 42,26.4756819 L46.3118341,22.1638478 C50.6338659,26.2252685 56.4224661,28.7085153 62.7838354,28.7085153 C69.2303078,28.7085153 75.0885835,26.1583816 79.428509,22 L83.7390387,26.3105297 C78.2995542,31.5936245 70.9161184,34.8402608 62.7838354,34.8402608 Z"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "icon-gps-path",
                      attrs: {
                        fill: "#FFF",
                        "fill-rule": "nonzero",
                        d:
                          "M63.3822159,47.0213654 C51.9674521,47.0213654 41.6088393,42.5958119 34,35.402235 L38.2262674,31.1759676 C44.7454557,37.3258412 53.6125309,41.1080164 63.3822159,41.1080164 C73.2447426,41.1080164 82.1874229,37.2536153 88.7233833,31 L92.9495438,35.2261605 C85.3240255,42.523529 74.8898213,47.0213654 63.3822159,47.0213654 Z"
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("circle", {
                    attrs: {
                      cx: "63",
                      cy: "6",
                      r: "6",
                      fill: "#FFF",
                      "fill-rule": "nonzero"
                    }
                  })
                ])
              ]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gps-text", attrs: { id: "gps-text" } }, [
      _c("span", { staticClass: "gps-text-word" }, [_vm._v("位")]),
      _c("span", { staticClass: "gps-text-word" }, [_vm._v("置")]),
      _c("span", { staticClass: "gps-text-word" }, [_vm._v("情")]),
      _c("span", { staticClass: "gps-text-word" }, [_vm._v("報")]),
      _c("span", { staticClass: "gps-text-word" }, [_vm._v("取")]),
      _c("span", { staticClass: "gps-text-word" }, [_vm._v("得")]),
      _c("span", { staticClass: "gps-text-word" }, [_vm._v("中")])
    ])
  }
]
render._withStripped = true
/* hot reload */
if (module.hot) {
  var api = require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (module.hot.data) {
      require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js").rerender('fa1b1f48', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    }
  }
}export { render, staticRenderFns }