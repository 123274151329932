import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import { pathname } from '../../data/pathname'
import { home } from './home'
import { result } from './result'

Vue.use(VueRouter)
Vue.use(Meta)

// ルーターの作成
const router = new VueRouter({
  mode: 'history',
  routes: [
    home,
    result,
    {
      path: '*',
      redirect: { name: pathname.home }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

// // ルーティング
// const routing = async () => {
//   console.log(store.state.distance.current)
//   if (store.state.distance.current !== null) return true
//
//   // 設定距離の初期値が存在しなければセットする
// }

router.beforeEach((to, from, next) => {
  next()
  // routing()
  //   .then(() => next())
  //   .catch(() => next({ name: pathname.home }))
})

export default router