/**
 * 距離定義
 */
export const distance = {
  '100m': 100,
  '200m': 200,
  '300m': 300,
  '400m': 400,
  '500m': 500,
  '600m': 600,
  '700m': 700,
  '800m': 800,
  '900m': 900,
  '1km': 1000,
  '2km': 2000,
  '3km': 3000
}