<template lang="pug">
  .result
    area-success
    area-fail

</template>

<style lang="stylus" scoped>
  @import "~stylus/_assets/modules/_getConsts.styl"

  .result
    position: relative
    width: 100%
    height: 100%
    z-index: $z.main
</style>

<script>
  import axios from 'axios'
  import { mapState, mapGetters } from 'vuex'
  import getLocation from '../utils/getLocation'
  import AreaSuccess from './modules/result/area-success'
  import AreaFail from './modules/result/area-fail'

  export default {
    components: { AreaSuccess, AreaFail },
    computed: {
      ...mapState({
        distance: (state) => state.distance.current
      }),
      ...mapGetters({
        isSuccess: 'loading/isSuccess',
        isFail: 'loading/isFail',
        lGPS: 'loading/gps',
        lYelp: 'loading/yelp',
        lFail: 'loading/fail'
      })
    },
    created () {
      this.$bus
        .$on('result-search', () => {
          this.connect()
        })
    },
    beforeDestroy () {
      this.$bus
        .$off('result-search')
    },
    beforeMount () {
    },
    mounted () {
      this.$nextTick(() => {
        this.connect()
      })
    },
    methods: {
      connect () {
        this.$bus.$emit('loading-open')

        // TODO: 立て続けに再検索を行うとローディングが進まなくなるので一時的な処理
        setTimeout(() => {
          this.connectGeo()
        }, 300)
      },

      async connectGeo () {
        const geo = await getLocation().catch((type) => {
          // 位置情報エラー
          console.error(type)
          this.$bus.$emit('fail-type', type.code, () => {
            this.$bus.$emit('loading-next', this.lGPS, this.lGPS, () => {
              this.$bus.$emit('loading-close')
            })
          })
        })
        console.log(geo)

        if (geo == null) return false

        this.$bus.$emit('loading-next', this.lGPS, this.lYelp, () => {
          this.connectYelp(geo)
        })
      },

      async connectYelp (geo) {
        const yelp = await axios.get('/yelp.php', {
          params: {
            lat: geo.lat,
            lng: geo.lng,
            radius: this.distance
          },
          timeout: 30000
        })

        const { status, res } = yelp.data

        switch (status) {
          case 'success':
            console.log('find')
            this.$bus.$emit('map-create', res, geo)
            break
          case 'empty':
            console.log('empty')
            this.$bus.$emit('fail-empty', () => {
              this.$bus.$emit('loading-next', this.lYelp, this.lYelp, () => {
                this.$bus.$emit('loading-close')
              })
            })
            break
          default:
            // error
            console.error(yelp.data.error)
            this.$bus.$emit('fail-unknown', () => {
              this.$bus.$emit('loading-next', this.lYelp, this.lYelp, () => {
                this.$bus.$emit('loading-close')
              })
            })
            break
        }
      }
    }
  }
</script>