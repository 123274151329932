import StorageControl from 'storage-control'
import { distance } from './data/distance'
import { pathname } from './data/pathname'
import router from './modules/router'
import store from './modules/store'
import site from '../../_ws/pug/data/site.yml'
// import VueTouch from 'vue-touch'

// webkit系のフォールバック
window.URL = window.URL || window.webkitURL

// ローカルストレージ
Vue.prototype.$storage = new StorageControl('local')

// イベントエミッター
Vue.prototype.$bus = new Vue()

// パスネーム
Vue.prototype.$pathname = pathname

// 拡張
// Vue.use(VueTouch)
// , { name: 'v-touch' }

// 距離データの初期値設定
const temp = Vue.prototype.$storage.get('distance')
store.dispatch('distance/update', {
  current: temp !== null ? temp : distance[Object.keys(distance)[0]]
})

const apps = new Vue({
  router,
  store,
  // @see: https://github.com/declandewet/vue-meta
  metaInfo: {
    titleTemplate: (chunk) => {
      return chunk ? `${chunk} | ${site.title}` : site.title
    }
  }
})
apps.$mount('#apps')