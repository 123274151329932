const init = 0

const state = {
  progress: init,
  failType: 0,
  splash: false
}

const mutations = {
  reset (state) {
    state.progress = init
    state.failType = 0
  },
  change (state, { progress }) {
    state.progress = progress
  },
  failType (state, { type }) {
    state.failType = type
  },
  endSplash (state) {
    state.splash = true
  }
}

const getters = {
  isGPS: (state, getters) => state.progress === getters.gps,
  isYelp: (state, getters) => state.progress === getters.yelp,
  isSuccess: (state, getters) => state.progress === getters.success,
  isFail: (state, getters) => state.progress === getters.fail,
  gps: (state) => 0,
  yelp: (state) => 1,
  success: (state) => 2,
  fail: (state) => 3,
  notSplash: (state) => !state.splash
}

const actions = {
  reset: ({ commit }) => commit('reset'),
  change: ({ commit }, { progress }) => commit('change', { progress }),
  failType: ({ commit }, { type }) => commit('failType', { type }),
  endSplash: ({ commit }) => commit('endSplash')
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}