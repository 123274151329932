<template lang="pug">
  transition(name="fade", mode="out-in")
    .success(v-if="show")
      .success-header
        h1.success-title: img(src="../../../../img/logo.svg", width=122, height=21, alt="KOKOIKU")
        small.success-author &copy; {{author}}

      #map.map

      .success-shop: shop-info

      ul.success-control
        li: button.button.button-image(type="button", @click="$bus.$emit('setting-open')"): img(src="../../../../img/button_settings.svg", alt="", width=58, height=58)
        li: button.button.button-image(type="button", @click="$bus.$emit('result-search')"): img(src="../../../../img/button_research.svg", alt="", width=58, height=58)
        //-li: change-location
</template>

<style lang="stylus" scoped>
  @import "~stylus/_assets/modules/_getConsts.styl"

  .success
    position: relative
    width: 100%
    height: 100%
    z-index: $z.main

    &-header
      position: absolute
      bottom: ($base.space * 1.5)
      left: $base.space
      z-index: ($z.main + 1)

    &-title
      margin-bottom: round($base.space / 3)

    &-shop
      position: absolute
      top: 0
      left: 0
      padding: $base.space
      box-sizing: border-box
      width: 100%
      z-index: ($z.main + 1)

    &-control
      position: absolute
      bottom: 30px
      right: 10px
      z-index: ($z.main + 1)
      list-style: none

  .map
    width: 100%
    height: 100%
</style>

<script>
  // import changeLocation from './modules/change-location'
  import iconCurrent from 'img/icon_current.svg'
  import iconShop from 'img/icon_shop.svg'
  import { defaults as defaultControls } from 'ol/control'
  import Attribution from 'ol/control/Attribution'
  import { boundingExtent } from 'ol/extent'
  import Feature from 'ol/Feature'
  import Point from 'ol/geom/Point'
  import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
  import Map from 'ol/Map'
  import { transform, transformExtent } from 'ol/proj'
  import VectorSource from 'ol/source/Vector'
  import XYZ from 'ol/source/XYZ'
  import { Icon, Style } from 'ol/style'
  import View from 'ol/View'
  import { author } from 'pug/data/site.yml'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import shopInfo from '../shop-info'

  export default {
    components: { shopInfo },
    data () {
      return {
        show: false,
        view: null,
        map: null,
        marker: {
          current: null,
          shop: null
        }
      }
    },
    computed: {
      author: () => author,
      epsg4326: () => 'EPSG:4326',
      epsg3857: () => 'EPSG:3857',
      ...mapState({
        distance: (state) => state.distance.current
      }),
      ...mapGetters({
        lGPS: 'loading/gps',
        lYelp: 'loading/yelp',
        lFail: 'loading/fail'
      })
    },
    created () {
      this.$bus
        .$on('map-hidden', () => {
          this.show = false
        })
        .$on('map-create', (data, geo) => {
          this.show = true
          this.createMap(data, geo)
        })
    },
    beforeDestroy () {
      this.map = null
      this.view = null
      this.marker.current = null
      this.marker.shop = null

      this.$bus
        .$off('map-hidden')
        .$off('map-create')
    },
    methods: {
      createMap (data, geo) {
        this.shopData({ data })
        const current = { lat: geo.lat, lng: geo.lng }
        const shop = { lat: data.coordinates.latitude, lng: data.coordinates.longitude }

        this.$nextTick(() => {
          this.makeMap(current, shop)
        })
      },

      /**
       * マップ生成
       * @param current
       * @param shop
       */
      makeMap (current, shop) {
        const pos = [[current.lng, current.lat], [shop.lng, shop.lat]]

        const currentPosition = transform(pos[0], 'EPSG:4326', 'EPSG:3857')
        const shopPosition = transform(pos[1], 'EPSG:4326', 'EPSG:3857')

        // 自身の位置をプロット
        this.marker.current = new Feature({
          geometry: new Point(currentPosition)
        })
        this.marker.current.setStyle(new Style({
          image: new Icon({
            src: iconCurrent
          })
        }))

        // 店の位置をプロット
        this.marker.shop = new Feature({
          geometry: new Point(shopPosition)
        })
        this.marker.shop.setStyle(new Style({
          image: new Icon({
            src: iconShop
          })
        }))

        // View
        this.view = new View({
          center: shopPosition,
          zoom: 17
        })

        // マップの生成
        this.map = new Map({
          target: 'map',
          pixelRatio: 2,
          layers: [
            new TileLayer({
              source: new XYZ({
                // custom tile: https://manage.thunderforest.com/dashboard
                url: 'https://{a-c}.tile.thunderforest.com/outdoors/{z}/{x}/{y}@2x.png?apikey=d8bdebd12e134c79879d4dcdf537ad3d',
                attributions: ['Maps &copy; <a href="https://www.thunderforest.com">Thunderforest</a>, Data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>'],
                tileSize: [256, 256],
                tilePixelRatio: 2
              })
            }),
            new VectorLayer({
              source: new VectorSource({
                features: [this.marker.current, this.marker.shop]
              })
            })
          ],
          view: this.view,
          controls: defaultControls({
            attribution: false,
            collapsible: false,
            collapsed: false,
            zoom: false
          }).extend([
            new Attribution({
              collapsible: false
            })
          ])
        })

        let ext = boundingExtent(pos)
        ext = transformExtent(ext, this.epsg4326, this.epsg3857)
        this.view.fit(ext, this.map.getSize() - 2)

        this.map.once('postrender', () => {
          this.$bus.$emit('loading-next', this.lYelp, this.lYelp, () => {
            this.$bus.$emit('loading-close')
          })
        })
      },

      /**
       * マーカーのみアップデート
       * @param current
       * @param shop
       */
      updateMarker (current, shop) {
        const pos = [[current.lng, current.lat], [shop.lng, shop.lat]]

        this.marker.current.setGeometry(new Point(transform(pos[0], this.epsg4326, this.epsg3857)))
        this.marker.shop.setGeometry(new Point(transform(pos[1], this.epsg4326, this.epsg3857)))
        this.locationShop()

        let ext = boundingExtent(pos)
        ext = transformExtent(ext, this.epsg4326, this.epsg3857)
        this.view.fit(ext, this.map.getSize() - 2)

        this.$bus.$emit('loading-next', this.lYelp, this.lYelp, () => {
          this.$bus.$emit('loading-close')
        })
      },

      // 現在地座標取得
      locationCurrent () {
        this._setCenter(this.marker.current.getGeometry())
      },

      // 店舗座標取得
      locationShop () {
        this._setCenter(this.marker.shop.getGeometry())
      },

      // 中心位置をセット
      _setCenter (position) {
        this.view.setCenter(position.flatCoordinates)
      },

      ...mapActions({
        shopData: 'shop/update'
      })
    }
  }
</script>