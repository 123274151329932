<template lang="pug">
  transition(name="fade", mode="out-in")
    .loading(v-if="show", :class="bgCls")
      .loading-container
        transition(name="fade", mode="out-in")
          area-gps(v-if="isGPS", key="gps")
          area-yelp(v-if="isYelp", key="yelp")
</template>

<style lang="stylus" scoped>
  @import "~stylus/_assets/modules/_getConsts.styl"

  .loading
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    overflow: hidden
    background: #ffffff
    z-index: $z.loading
    box-sizing: border-box
    transition: background 0.3s ease
    &.gps
      background: $c.main
    &.yelp
      background: lighten($c.main, 40%)

    &-container
      overflow-y: auto
      -webkit-overflow-scrolling: touch
      box-sizing: border-box
      height: 100%
</style>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import areaGps from './area-gps'
  import areaYelp from './area-yelp'

  export default {
    components: { areaGps, areaYelp },
    data () {
      return {
        show: true
      }
    },
    computed: {
      bgCls () {
        if (this.isGPS) return 'gps'
        if (this.isYelp) return 'yelp'

        return false
      },
      ...mapGetters({
        isGPS: 'loading/isGPS',
        isYelp: 'loading/isYelp',
        isFail: 'loading/isFail',
        gps: 'loading/gps',
        yelp: 'loading/yelp',
        fail: 'loading/fail'
      })
    },
    created () {
      this.$bus.$on('loading-next', (current, next, cb = null) => {
        switch (current) {
          case this.gps:
            this.$bus.$emit('gps-finish', () => {
              this.change({ progress: next })
              if (cb) cb()
            })
            break
          case this.yelp:
            this.$bus.$emit('yelp-finish', () => {
              this.change({ progress: next })
              if (cb) cb()
            })
            break
          default:
            this.change({ progress: this.fail })
            break
        }
      })

      this.$bus.$on('loading-open', () => {
        this.show = true
        this.reset()
        this.$bus.$emit('map-hidden')
        this.$bus.$emit('fail-hidden')
      })

      this.$bus.$on('loading-close', () => {
        this.show = false
      })
    },
    beforeDestroy () {
      this.$bus
        .$off('loading-next')
        .$off('loading-open')
        .$off('loading-close')
        // .$off('gps-finish')
        // .$off('yelp-finish')
    },
    methods: {
      ...mapActions({
        reset: 'loading/reset',
        change: 'loading/change'
      })
    }
  }
</script>