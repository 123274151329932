var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _vm.show
      ? _c("div", { staticClass: "loading", class: _vm.bgCls }, [
          _c(
            "div",
            { staticClass: "loading-container" },
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [
                  _vm.isGPS ? _c("area-gps", { key: "gps" }) : _vm._e(),
                  _vm._v(" "),
                  _vm.isYelp ? _c("area-yelp", { key: "yelp" }) : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
/* hot reload */
if (module.hot) {
  var api = require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (module.hot.data) {
      require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js").rerender('ed053d7a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    }
  }
}export { render, staticRenderFns }