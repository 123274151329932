export default () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // 成功
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
      },
      (err) => {
        // エラー
        // { code: number, error: string }
        // 1 PERMISSION_DENIED: ページにアクセス許可がないため、位置情報の取得に失敗
        // 2 POSITION_UNAVAILABLE: 内部的なエラーを返したため、位置情報の取得に失敗
        // 3 TIMEOUT: タイムアウト
        reject(err)
      },
      {
        enableHighAccuracy: true, // 高精度の結果を求めるか(電力消費が増える)
        timeout: 60000, // 取得のタイムアウト
        maximumAge: 60000 // キャッシュ済みの位置情報有効期限
      }
    )
  })
}