var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ad", attrs: { id: "ad" } },
    [
      _vm.production
        ? [
            _c("ins", {
              staticClass: "adsbygoogle",
              style: _vm.adStyle,
              attrs: {
                "data-ad-client": _vm.adclient,
                "data-ad-slot": _vm.adslot,
                "data-ad-format": _vm.adformat,
                "data-full-width-responsive": _vm.adresponsive
              }
            })
          ]
        : [_c("div", { staticClass: "ad-span" }, [_vm._v("広告")])]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
/* hot reload */
if (module.hot) {
  var api = require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (module.hot.data) {
      require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js").rerender('9150223c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    }
  }
}export { render, staticRenderFns }