<template lang="pug">
  .yelp
    #yelp-text.yelp-text
      span.yelp-text-word 店
      span.yelp-text-word 舗
      span.yelp-text-word 情
      span.yelp-text-word 報
      span.yelp-text-word 取
      span.yelp-text-word 得
      span.yelp-text-word 中

    #yelp-logo.yelp-logo
      .yelp-logo-base: include ../../parts/icon_loading_base.pug
      #yelp-arrow.yelp-logo-arrow: include ../../parts/icon_loading_arrow.pug
</template>

<style lang="stylus" scoped>
  @import "~stylus/_assets/modules/_getConsts.styl"

  .yelp
    display: flex
    flex-flow: column nowrap
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    color: #ffffff

    &-text
      font-weight: bold
      font-size: $base.fz.title
      letter-spacing: 10px
      margin-bottom: $base.space
      white-space: nowrap

      &-word
        display: inline-block
        margin: 0 5px

    &-logo
      width: 74px
      height: 74px
      position: relative

      &-base
        position: absolute
        top: 0
        left: 0

      &-arrow
        position: absolute
        top: 0
        left: 30px
        bottom: 0
        margin: auto 0
        width: 67px
        height: 21px

  //transform: translateX(200px)

  #icon-loading-arrow
    transform-origin: left center
</style>

<script>
  import animejs from 'animejs'

  export default {
    data () {
      return {
        animation: []
      }
    },
    computed: {},
    created () {
      this.$bus.$on('yelp-finish', (cb) => {
        this.end(cb)
      })
    },
    beforeDestroy () {
      this.$bus.$off('yelp-finish')
    },
    mounted () {
      this.$nextTick(() => {
        this.start()
      })
    },
    methods: {
      start () {
        const a = animejs
          .timeline({
            delay: 900,
            loop: true
          })
          .add({
            targets: '#yelp-arrow',
            easing: 'easeInCirc',
            duration: 500,
            translateX: ['200px', '0px'],
            opacity: [0, 1]
          })
          .add({
            targets: '#icon-loading-arrow',
            offset: 490,
            easing: 'linear',
            duration: 100,
            elasticity: 0,
            loop: 4,
            rotate: [
              { value: -10 },
              { value: 10 },
              { value: 0 }
            ],
            scaleX: [
              { value: 0.8 },
              { value: 1 }
            ],
            opacity: 1
          })
          .add({
            targets: '#yelp-arrow',
            duration: 500,
            translateX: '0px',
            opacity: 1
          })

        this.animation.push(a)

        const b = animejs({
          targets: '#yelp-text .yelp-text-word',
          loop: true,
          ease: 'easeInSine',
          elasticity: 0,
          scale: [0.6, 1],
          opacity: [0, 1],
          translateY: [100, 0],
          duration: (el, i, l) => (i * 200) + 200,
          delay: (el, i, l) => i * 100
        })

        this.animation.push(b)
      },
      end (cb) {
        this.animation.forEach((anim) => {
          anim.pause()
        })

        animejs.timeline()
          .add({
            targets: '#yelp-arrow',
            ease: 'linear',
            elasticity: 0,
            duration: 100,
            translateX: '0px',
            opacity: 1
          })
          .add({
            targets: '#yelp-text .yelp-text-word',
            elasticity: 0,
            ease: 'easeInSine',
            scale: 0,
            duration: 1000
          })
          .add({
            targets: '#yelp-logo',
            elasticity: 0,
            rotate: [
              { value: -35, delay: 0, duration: 500, ease: 'easeInCirc' }
            ],
            scale: [
              { value: 0.9, delay: 500, duration: 300, ease: 'ease' },
              { value: 2.5, delay: 500, ease: 'ease' }
            ],
            opacity: [
              { value: 0, delay: 1300, ease: 'ease' }
            ],
            duration: 1500,
            complete: () => {
              if (cb) cb()
            }
          })
      }
    }
  }
</script>