var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "distance" },
    _vm._l(_vm.distance, function(val, key) {
      return _c("li", { key: val, staticClass: "distance-list" }, [
        _c(
          "button",
          {
            staticClass: "button distance-button",
            class: { active: _vm.isActive(val) },
            attrs: { type: "button" },
            on: {
              mouseup: function($event) {
                $event.stopPropagation()
                _vm.update({ current: val })
              },
              touchstart: function($event) {
                $event.stopPropagation()
                _vm.scaleOut($event)
              },
              touchend: function($event) {
                $event.stopPropagation()
                _vm.scaleIn($event, val)
              }
            }
          },
          [_vm._v(_vm._s(key))]
        )
      ])
    })
  )
}
var staticRenderFns = []
render._withStripped = true
/* hot reload */
if (module.hot) {
  var api = require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (module.hot.data) {
      require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js").rerender('3cacd77a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    }
  }
}export { render, staticRenderFns }