var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "result" },
    [_c("area-success"), _vm._v(" "), _c("area-fail")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
/* hot reload */
if (module.hot) {
  var api = require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (module.hot.data) {
      require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js").rerender('a2eb4418', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    }
  }
}export { render, staticRenderFns }