var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _vm.show
      ? _c("div", { staticClass: "splash", attrs: { id: "splash" } }, [
          _c(
            "div",
            {
              staticClass: "splash-container",
              attrs: { id: "splash-container" }
            },
            [
              _c(
                "div",
                { staticClass: "splash-arrow", attrs: { id: "splash-arrow" } },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        id: "icon-loading-arrow",
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "67",
                        height: "21",
                        viewBox: "0 0 67 21"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          fill: "#FF9E00",
                          "fill-rule": "evenodd",
                          d:
                            "M16.1193195,5.61208134 L64.4361148,5.61208134 C65.3276335,5.61208134 65.6509198,5.70490696 65.9768457,5.87921405 C66.3027716,6.05352114 66.5585602,6.30930972 66.7328673,6.63523564 C66.9071744,6.96116157 67,7.2844478 67,8.17596654 L67,13.0481961 C67,13.9397149 66.9071744,14.2630011 66.7328673,14.588927 C66.5585602,14.914853 66.3027716,15.1706415 65.9768457,15.3449486 C65.6509198,15.5192557 65.3276335,15.6120813 64.4361148,15.6120813 L16.2046996,15.6120813 L18.3522895,20.7874671 L0,10.6120813 L18.3522895,0 L16.1193195,5.61208134 Z"
                        }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "splash-main" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      id: "icon-splash",
                      height: "84",
                      viewBox: "0 0 485 84",
                      width: "485",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c(
                      "g",
                      { attrs: { fill: "none", "fill-rule": "evenodd" } },
                      [
                        _c("g", { attrs: { fill: "#000", stroke: "#000" } }, [
                          _c("path", {
                            staticClass: "icon-splash-path",
                            attrs: {
                              d:
                                "m84.8378887 42.46875c0-12.5599457 6.1044664-19.9824219 15.6367183-19.9824219 9.574576 0 15.685547 7.4135361 15.685547 19.9824219v8.6914062c0 12.4816616-6.023281 19.5429688-15.685547 19.5429688-9.6197262 0-15.6367183-7.0699657-15.6367183-19.5429688zm-12.4277344 8.6425781c0 18.5284745 10.4756427 29.578125 28.0156247 29.578125 17.562763 0 28.113282-11.0734685 28.113282-29.578125v-8.5449219c0-18.6921143-10.533698-30.0664062-28.015625-30.0664062-17.4755139 0-28.1132817 11.3750388-28.1132817 30.0664062z"
                            }
                          }),
                          _vm._v(" "),
                          _c("path", {
                            staticClass: "icon-splash-path",
                            attrs: {
                              d:
                                "m52.4522854 13.7695312h-13.206714l-25.2441407 29.8339844h-1.3178369v-29.8339844h-12.1835938v65.6503907h12.1835938v-21.0754736l7.3993336-8.403529 18.8626114 29.4790026h14.6711988l-24.5006603-37.4744351 23.3362081-28.1759555z"
                            }
                          }),
                          _vm._v(" "),
                          _c("path", {
                            staticClass: "icon-splash-path",
                            attrs: {
                              d:
                                "m206.965953 13.7695312h-13.206714l-25.24414 29.8339844h-1.317837v-29.8339844h-12.183594v65.6503907h12.183594v-21.0754736l7.399333-8.403529 18.862612 29.4790026h14.671199l-24.500661-37.4744351 23.336208-28.1759555z"
                            }
                          }),
                          _vm._v(" "),
                          _c("path", {
                            staticClass: "icon-splash-path",
                            attrs: {
                              d:
                                "m326.978506 79.4199219v-65.6503907h-12.183594v65.6503907z"
                            }
                          }),
                          _vm._v(" "),
                          _c("path", {
                            staticClass: "icon-splash-path",
                            attrs: {
                              d:
                                "m408.090946 13.7695312h-13.206714l-25.244141 29.8339844h-1.317837v-29.8339844h-12.183594v65.6503907h12.183594v-21.0754736l7.399334-8.403529 18.862611 29.4790026h14.671199l-24.50066-37.4744351 23.336208-28.1759555z"
                            }
                          }),
                          _vm._v(" "),
                          _c("path", {
                            staticClass: "icon-splash-path",
                            attrs: {
                              d:
                                "m442.966784 13.7695312h-12.183594v43.640625c0 14.0799239 10.206603 23.2792969 26.453125 23.2792969 16.204621 0 26.404297-9.2052408 26.404297-23.2792969v-43.640625h-12.183594v41.8339844c0 9.2095517-5.54736 14.8554688-14.220703 14.8554688-8.763932 0-14.269531-5.5894409-14.269531-14.8554688z"
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("g", { attrs: { id: "icon-splash-circle" } }, [
                          _c("ellipse", {
                            attrs: {
                              cx: "261",
                              cy: "42",
                              fill: "#734f15",
                              rx: "41",
                              ry: "40"
                            }
                          }),
                          _vm._v(" "),
                          _c("ellipse", {
                            attrs: {
                              cx: "261",
                              cy: "42",
                              fill: "#fff",
                              rx: "28.810811",
                              ry: "28.108108"
                            }
                          }),
                          _vm._v(" "),
                          _c("ellipse", {
                            attrs: {
                              cx: "261",
                              cy: "42",
                              fill: "#ff9e00",
                              rx: "16.621622",
                              ry: "16.216216"
                            }
                          })
                        ])
                      ]
                    )
                  ]
                )
              ])
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
/* hot reload */
if (module.hot) {
  var api = require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (module.hot.data) {
      require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js").rerender('48fe1fa6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    }
  }
}export { render, staticRenderFns }