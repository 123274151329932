<template lang="pug">
  transition(name="fade", mode="out-in")
    .fail(v-if="show")
      h1.fail-title {{failText}}

      .fail-contents
        .fail-sec: options-distance
        .fail-sec.fail-center: button.button.button-submit(type="button", @click="$bus.$emit('result-search')") 検索

      aside.fail-sec: area-ad(adclient="ca-pub-0251375114144720", adslot="1573121416", adresponsive="true", adformat="auto")

      ul.fail-sec.caution
        li.caution-list(v-for="list in caution", :key="list", v-html="list")

      footer.copy
        small.copy-author &copy; {{author}}
</template>

<style lang="stylus" scoped>
  @import "~stylus/_assets/modules/_getConsts.styl"

  .fail
    padding: $base.space
    position: relative
    width: 100%
    height: 100%
    overflow-y: auto
    -webkit-overflow-scrolling: touch
    &-title
      text-align: center
      font-size: $base.fz.title
      margin-bottom: ($base.space * 2)
      font-weight: bold
      line-height: 1.3

    &-sec
      margin-bottom: $base.space

    &-center
      text-align: center

  .copy
    text-align: center
    &-author
      font-size: 1.2rem
</style>

<script>
  import { author } from 'pug/data/site.yml'
  import { mapActions, mapState } from 'vuex'
  import { caution } from '../../../consts/caution'
  import AreaAd from '../ad'
  import OptionsDistance from '../options-distance'

  export default {
    components: { OptionsDistance, AreaAd },
    data () {
      return {
        show: false
      }
    },
    computed: {
      failText () {
        switch (this.type) {
          case 1: // PERMISSION_DENIED
            return 'アクセス許可がないため位置情報が取得できませんでした'
          case 2: // POSITION_UNAVAILABLE
            return '内部エラーで位置情報を取得できませんでした'
          case 3: // TIMEOUT
            return '位置情報取得のタイムアウト'
          case 10:
            return '近くに営業中の店舗がありませんでした'
          default:
            return '取得できませんでした'
        }
      },
      caution: () => [caution.yelp],
      author: () => author,
      ...mapState({
        type: (state) => state.loading.failType
      })
    },
    created () {
      this.$bus
        .$on('fail-hidden', () => {
          this.show = false
        })
        .$on('fail-type', (type, cb = null) => {
          this.show = true
          this.failType({ type })
          if (cb) cb()
        })
        .$on('fail-empty', (cb = null) => {
          this.show = true
          this.failType({ type: 10 })
          if (cb) cb()
        })
        .$on('fail-unknown', (cb = null) => {
          this.show = true
          this.failType({ type: 99 })
          if (cb) cb()
        })
    },
    beforeDestroy () {
      this.$bus
        .$off('fail-hidden')
        .$off('fail-empty')
        .$off('fail-unknown')
    },
    methods: {
      ...mapActions({
        failType: 'loading/failType'
      })
    }
  }
</script>