<template lang="pug">
  ul.distance
    li.distance-list(v-for="(val, key) in distance", :key="val")
      button.button.distance-button(type="button", :class="{ active: isActive(val) }", @mouseup.stop="update({current: val})", @touchstart.stop="scaleOut($event)", @touchend.stop="scaleIn($event, val)") {{key}}
</template>

<style lang="stylus" scoped>
  @import "~stylus/_assets/modules/_getConsts.styl"

  .distance
    list-style: none
    margin: 0
    padding: 0
    width: 100%
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-column-gap: $base.space
    grid-row-gap: $base.space

    &-button
      background: #fff
      border: 1px solid lighten($c.sub, 20%)
      width: 100%
      height: 40px
      color: $c.sub
      font-family: $font-numbers
      box-shadow: 1px 1px 2px lighten($c.main, 50%)
      border-radius: 4px
      &.active
        background: $c.main
        border-color: darken($c.main, 20%)
        color: #ffffff
        box-shadow: none
        border-radius: 0
</style>

<script>
  import animejs from 'animejs'
  import { mapActions, mapState } from 'vuex'
  import { distance } from '../../data/distance'

  export default {
    data () {
      return {
        animation: null,
        target: null
      }
    },
    computed: {
      ...mapState({
        current: (state) => state.distance.current
      }),
      distance () {
        return distance
      }
    },
    methods: {
      isActive (val) {
        return val === this.current
      },

      scaleOut (event) {
        if (/active/.test(event.target.className)) return false

        this.animation = animejs({
          targets: event.target,
          scale: 0.8,
          duration: 400,
          complete: () => {
            this.animation = null
          }
        })
      },

      scaleIn (event, val) {
        if (/active/.test(event.target.className)) return false
        if (this.animation) this.animation.pause()

        this.animation = animejs({
          targets: event.target,
          scale: [0.8, 1],
          duration: 300,
          complete: () => {
            this.animation = null
          }
        })

        this.update({ current: val })
      },

      ...mapActions({
        update: 'distance/update'
      })
    }
  }
</script>