<template lang="pug">
  .home
    h1.home-logo: img(src="../../img/logo.svg", width=162, height=28, alt="KOKOIKU")

    section.home-distance
      .home-distance-select: options-distance
      .home-distance-submit: button.button.button-submit(type="button", @click="$router.push({ name: $pathname.result})") 検索

    aside.home-ad: area-ad(adclient="ca-pub-0251375114144720", adslot="1573121416", adresponsive="true", adformat="auto")

    section.home-caution
      ul.caution
        li.caution-list(v-for="list in caution", :key="list", v-html="list")

    footer.copy
      small.copy-author &copy; {{author}}

</template>

<style lang="stylus" scoped>
  @import "~stylus/_assets/modules/_getConsts.styl"

  .home
    padding: $base.space
    width: 100%
    height: 100%
    overflow-y: auto
    -webkit-overflow-scrolling: touch
    background: #ffffff

    &-logo
      text-align: center
      margin-bottom: ($base.space * 2)

    &-title
      font-size: $base.fz.title
      font-weight: bold
      margin-bottom: $base.space

    &-distance
      margin-bottom: ($base.space * 2)
      &-select
        margin-bottom: ($base.space * 2)
      &-submit
        text-align: center

    &-ad
    &-caution
      margin-bottom: $base.space

  .copy
    text-align: center
    &-author
      font-size: 1.2rem
</style>

<script>
  import { author } from 'pug/data/site.yml'
  import { caution } from '../consts/caution'
  import AreaAd from './modules/ad'
  import OptionsDistance from './modules/options-distance'

  export default {
    components: { OptionsDistance, AreaAd },
    computed: {
      caution: () => [caution.location, caution.yelp],
      author: () => author,
      production: () => IS_PRODUCTION
    }
  }
</script>