import { render, staticRenderFns } from "./shop-rating.vue?vue&type=template&id=006509b6&scoped=true&lang=pug"
import script from "./shop-rating.vue?vue&type=script&lang=js"
export * from "./shop-rating.vue?vue&type=script&lang=js"
import style0 from "./shop-rating.vue?vue&type=style&index=0&id=006509b6&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "006509b6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('006509b6', component.options)
    } else {
      api.reload('006509b6', component.options)
    }
  }
}
component.options.__file = "vue/modules/shop-rating.vue"
export default component.exports