var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yelp" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "yelp-logo", attrs: { id: "yelp-logo" } }, [
      _c("div", { staticClass: "yelp-logo-base" }, [
        _c(
          "svg",
          {
            attrs: {
              id: "icon-loading-base",
              xmlns: "http://www.w3.org/2000/svg",
              width: "74",
              height: "74",
              viewBox: "0 0 74 74"
            }
          },
          [
            _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
              _c("circle", {
                attrs: { cx: "37", cy: "37", r: "37", fill: "#734F15" }
              }),
              _vm._v(" "),
              _c("circle", {
                attrs: { cx: "37", cy: "37", r: "26", fill: "#FFF" }
              }),
              _vm._v(" "),
              _c("circle", {
                attrs: { cx: "37", cy: "37", r: "15", fill: "#FF9E00" }
              })
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "yelp-logo-arrow", attrs: { id: "yelp-arrow" } },
        [
          _c(
            "svg",
            {
              attrs: {
                id: "icon-loading-arrow",
                xmlns: "http://www.w3.org/2000/svg",
                width: "67",
                height: "21",
                viewBox: "0 0 67 21"
              }
            },
            [
              _c("path", {
                attrs: {
                  fill: "#FF9E00",
                  "fill-rule": "evenodd",
                  d:
                    "M16.1193195,5.61208134 L64.4361148,5.61208134 C65.3276335,5.61208134 65.6509198,5.70490696 65.9768457,5.87921405 C66.3027716,6.05352114 66.5585602,6.30930972 66.7328673,6.63523564 C66.9071744,6.96116157 67,7.2844478 67,8.17596654 L67,13.0481961 C67,13.9397149 66.9071744,14.2630011 66.7328673,14.588927 C66.5585602,14.914853 66.3027716,15.1706415 65.9768457,15.3449486 C65.6509198,15.5192557 65.3276335,15.6120813 64.4361148,15.6120813 L16.2046996,15.6120813 L18.3522895,20.7874671 L0,10.6120813 L18.3522895,0 L16.1193195,5.61208134 Z"
                }
              })
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "yelp-text", attrs: { id: "yelp-text" } }, [
      _c("span", { staticClass: "yelp-text-word" }, [_vm._v("店")]),
      _c("span", { staticClass: "yelp-text-word" }, [_vm._v("舗")]),
      _c("span", { staticClass: "yelp-text-word" }, [_vm._v("情")]),
      _c("span", { staticClass: "yelp-text-word" }, [_vm._v("報")]),
      _c("span", { staticClass: "yelp-text-word" }, [_vm._v("取")]),
      _c("span", { staticClass: "yelp-text-word" }, [_vm._v("得")]),
      _c("span", { staticClass: "yelp-text-word" }, [_vm._v("中")])
    ])
  }
]
render._withStripped = true
/* hot reload */
if (module.hot) {
  var api = require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (module.hot.data) {
      require("/Users/artproejctteam/_www/kokoiku/node_modules/vue-hot-reload-api/dist/index.js").rerender('4a48b564', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    }
  }
}export { render, staticRenderFns }